
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsDialog,
  },
})
export default class ExportData extends Vue {
  $refs!: { dialog: any }
  @Prop() method!: Function // 列表接口api
  @Prop() param!: object // 列表接口参数

  @Prop() userId?: number // 有些接口需要用户id，如用户邀请列表
  @Prop() type?: string // 有些接口是共用的，如用户余额/积分列表共用一个接口，需要一个额外参数来判断
  @Prop() pageSize?: number // 对应列表右下角的每页的数量
  // exportList:any = api
  exportData = {}
  formData = {
    page_type: 0,
    page_start: 1,
    page_end: 200,
    file_name: '',
  }

  // 监听pageSize变化
  @Watch('param', { immediate: true, deep: true })
  paramChange(val: number) {
    this.getData()
  }

  @Watch('pageSize', { immediate: true })
  pageSizeChange(val: number) {
    this.getData()
  }
  // get apiFun() {
  //     if(!this.exportList[this.apiName]) {
  //         return () => {
  //             return Promise.reject()
  //         }
  //     }
  //     return this.exportList[this.apiName]
  // }

  handleConfirm() {
    const loading = this.$loading({
      lock: true,
      text: '正在导出中...',
      spinner: 'el-icon-loading',
    })
    this.method({
      export: 2,
      ...this.param,
      ...this.formData,
      user_id: this.userId,
      type: this.type,
      page_size: this.pageSize,
    })
      .then(() => {
        this.$refs.dialog.close()
        // loading.close()
      })
      .finally(() => {
        loading.close()
      })
  }

  getData() {
    this.method({
      ...this.param,
      export: 1,
      user_id: this.userId,
      type: this.type,
      page_size: this.pageSize,
    }).then((res: any) => {
      this.exportData = res
      this.formData.file_name = res.file_name
      this.formData.page_end = res.page_end
      this.formData.page_start = res.page_start
    })
  }

  created() {}
}
